import { FC, useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import {
    Upload,
    message,
    Modal,
    DatePicker,
    Input,
    ConfigProvider,
    Spin,
} from 'antd';
import useSPQuoteForm, { ReceivedProps, Props } from './hook';
import { SPQuoteFormStyles } from './styled';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { handleFilter } from 'utils/helper/filterDropdown';
import { QuoteStatusEnum } from 'utils/enums';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import quoteValidation from 'utils/validation/registers/quote';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import quoteApi from 'services/quoteApi';
import './style.scss';
import { validateZipcode } from 'utils/helper/appHelper';
import jaJP from 'antd/es/locale/ja_JP';
import { LoadingOutlined } from '@ant-design/icons';
import { PatternFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { fullWidthNumConvert } from 'pages/invoice';

const { TextArea } = Input;

const UploadCustom = styled(Upload)<{ $isFull?: boolean }>`
    .ant-upload-list-picture-card {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;

const SPQuoteFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dragProps,
    dummyRequest,
    setError,
    register,
    screenType,
    isLoading,
    errors,
    getValues,
    setValue,
    listFile,
    setListFile,
    fileLength,
    setFileLength,
    isExcessiveUpload,
    setIsExcessiveUpload,
    data,
    clearErrors,
    navigateBackToDetail,
    handleValidateWhitespace,
    totalImageSize,
    isAllowUpload,
    setAllowUpload,
    setTotalImageSize,
}) => {
    const UPLOAD_LIMIT = 50;
    const { t } = useTranslation();
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);
    const parsePostalCode = (zipCode: string) => {
        if (!zipCode) {
            setError('zipCode', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);

        if (!isZipcodeFormat) {
            setError('zipCode', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');

        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address', foundAddress);
                clearErrors('address');
                clearErrors('zipCode');
            } else {
                setValue('address', '');
                setError('zipCode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10 - fileLength;
        const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
            listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterReMove: any =
            loadFile < filteredPdfAfterRedundant?.length &&
            filteredPdfAfterRedundant?.slice(0, loadFile > 1 ? loadFile : 1);

        if (info.file.status === 'done') {
            setTotalImageSize((prevSize) => {
                return prevSize + (info.file.size as number);
            });
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile > 0) {
            if (filteredPdfAfterReMove?.length > 0) {
                setListFile(
                    filteredPdfAfterUid?.concat(filteredPdfAfterReMove),
                );
                setFileLength(
                    filteredPdfAfterUid?.length +
                        filteredPdfAfterReMove?.length,
                );
                setAllowUpload(
                    filteredPdfAfterUid?.length +
                        filteredPdfAfterReMove?.length <
                        10,
                );
                setValue('images', filteredPdfAfterUid);
            } else {
                setAllowUpload(info.fileList.length < 10);
                setValue('images', info.fileList);
                setFileLength(info?.fileList?.length);
                setListFile(info?.fileList);
            }
        } else {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        }
        clearErrors('images');
    };
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const id = useParams();
    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const availableSlots = 10 - fileLength;
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        if (fileList.length > availableSlots) {
            setIsExcessiveUpload(true);
            return Upload.LIST_IGNORE;
        }
        const accumulatedImageSize = totalImageSize + uploadingSizes;

        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;

        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };
    useEffect(() => {
        if (isExcessiveUpload) {
            message.error('ファイルアップロードは10ファイルまでです。');
            setIsExcessiveUpload(false);
        }
    }, [isExcessiveUpload]);
    const wordLimit = 5000;
    const typeOptions: SelectSearchOption[] = [
        { value: 'interior', name: '内装' },
        { value: 'exterior', name: '外装' },
        { value: 'water_area', name: '水回り' },
        { value: 'other', name: 'その他' },
    ];

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        );
    };

    const getDetail = async () => {
        try {
            const res = await quoteApi.getSPQuoteDetail(
                user.access_token,
                id?.id,
            );
            if (res) {
                const data = res.data.quote;
                setValue('title', data?.title);
                setValue('note', data?.note);
                setValue('quote_type', data?.quote_type);
                setValue('address', data?.address);
                setValue('status', data?.status);
                // console.log('data?.images[0].image_path', data?.images[0].image_path)
                const fileArr: any = [];
                if (data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                // const newFile = {
                                //     uid: '-3',
                                //     name: myFile.name,
                                //     // lastModified: myFile.lastModified,
                                //     // lastModifiedDate: myFile.lastModifiedDate,
                                //     // size: myFile.size,
                                //     // type: myFile.type,
                                //     // file: myFile.file,
                                //     url: data?.images[0].image_path,
                                //     status: 'done',
                                //     // originFileObj: myFile
                                // }
                                // fileArr.push(newFile)
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                            });
                    }
                }
                setValue('images', fileArr);
                setListFile(fileArr);
                setFileLength(fileArr.length);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    useEffect(() => {
        if (id?.id) {
            // getDetail()
        } else {
            setValue('quote_type', typeOptions[0].value);
        }
    }, [id]);
    const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
        value: item.value,
        name: item.title,
    }));
    const uploadButton = (
        <div className="text-[#5392F0] flex flex-col items-center pr-[5px]">
            <img
                src={uploadIcon}
                className="w-[21px] h-[21px]"
                alt="upload image icon"
            />
            <div>写真 ({listFile?.length > 0 ? listFile?.length : 0}/10)</div>
        </div>
    );
    return (
        <>
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 1000,
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin />
                    </div>
                )}
                <SPQuoteFormStyles>
                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="w-full flex flex-col gap-y-[14px]">
                            <div className="flex flex-col w-full">
                                <div className="flex items-center">
                                    <div className="mr-4">写真アップロード</div>
                                    <img
                                        src={warningIcon}
                                        alt="warning circle icon"
                                    />
                                    <div className="text-[#808080]">
                                        1:1 スケール画像
                                    </div>
                                </div>
                                <div>
                                    {isMac ? (
                                        <UploadCustom
                                            locale={{
                                                uploading: 'アップロード中',
                                            }}
                                            listType="picture-card"
                                            customRequest={dummyRequest}
                                            {...register('images')}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleBeforeUpload}
                                            fileList={listFile}
                                            onPreview={handlePreview}
                                            multiple
                                            accept=".jpg, .jpeg, .png, .gif"
                                            $isFull={
                                                typeof fileLength ===
                                                    'number' && fileLength >= 10
                                            }
                                        >
                                            {isAllowUpload && uploadButton}
                                        </UploadCustom>
                                    ) : (
                                        <UploadCustom
                                            locale={{
                                                uploading: 'アップロード中',
                                            }}
                                            listType="picture-card"
                                            customRequest={dummyRequest}
                                            {...register('images')}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleBeforeUpload}
                                            fileList={listFile}
                                            onPreview={handlePreview}
                                            multiple
                                            // accept=".jpg, .jpeg, .png, .gif"
                                            $isFull={
                                                typeof fileLength ===
                                                    'number' && fileLength >= 10
                                            }
                                        >
                                            {isAllowUpload && uploadButton}
                                        </UploadCustom>
                                    )}
                                </div>

                                <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img
                                        alt="example"
                                        style={{ width: '100%' }}
                                        src={previewImage}
                                    />
                                </Modal>

                                {errors.images && (
                                    <span className="error !text-[14px]">
                                        {errors.images.message?.toString()}
                                    </span>
                                )}
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="content"
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                依頼内容
                                            </p>
                                            <textarea
                                                autoComplete="off"
                                                className="border-[1px] border-solid border-[#cdd1d5]"
                                                rows={4}
                                                {...register(
                                                    'content',
                                                    quoteValidation().content(),
                                                )}
                                                {...field}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            />
                                            {errors.content && (
                                                <span className="error !text-[14px]">
                                                    {errors.content.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        タイプ
                                    </p>
                                </div>
                                <Controller
                                    name="quote_type"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <SelectSearch
                                            options={typeOptions}
                                            search
                                            filterOptions={handleFilter}
                                            {...register(
                                                'quote_type',
                                                quoteValidation().type(),
                                            )}
                                            {...field}
                                            emptyMessage={'該当なし'}
                                            placeholder=""
                                        />
                                    )}
                                />
                                {errors.quote_type && (
                                    <span className="error !text-[14px]">
                                        {errors.quote_type.message?.toString()}
                                    </span>
                                )}
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="dateTime"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                工事希望日
                                            </p>
                                            <DatePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'dateTime',
                                                    quoteValidation()?.dateTime(),
                                                )}
                                                {...field}
                                                format={'YYYY年MM月DD日'}
                                            />

                                            {errors.dateTime && (
                                                <span className="error !text-[14px]">
                                                    {errors.dateTime.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register(
                                                'zipCode',
                                                quoteValidation(t).zipCode(),
                                            );
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    現場：郵便番号
                                                </p>
                                                <div className="flex gap-[10px]">
                                                    {/* <PatternFormat
                                                        className="textBox"
                                                        format="###-####"
                                                        placeholder="000-0000"
                                                        mask="_"
                                                        {...field}
                                                        name={name}
                                                        onChange={(e) => {
                                                            clearErrors(
                                                                'zipCode',
                                                            );
                                                            onChange(e);
                                                        }}
                                                        onBlur={onBlur}
                                                        getInputRef={ref}
                                                    /> */}
                                                    <Input
                                                        placeholder="000-0000"
                                                        {...register('zipCode')}
                                                        onBlur={(evt) => {
                                                            let string =
                                                                evt.target.value.replace(
                                                                    'ー',
                                                                    '-',
                                                                );
                                                            string =
                                                                string.replace(
                                                                    '－',
                                                                    '-',
                                                                );
                                                            string =
                                                                string.replace(
                                                                    '−',
                                                                    '-',
                                                                );
                                                            setValue(
                                                                'zipCode',
                                                                fullWidthNumConvert(
                                                                    string,
                                                                ),
                                                            );
                                                        }}
                                                        maxLength={8}
                                                        value={field.value}
                                                        onChange={(evt) => {
                                                            // let string = evt.target.value.replace('ー', '-')
                                                            // string = string.replace('－', '-')
                                                            field.onChange(
                                                                evt.target
                                                                    .value,
                                                            );
                                                            setValue(
                                                                'zipCode',
                                                                evt.target
                                                                    .value,
                                                            );
                                                            clearErrors(
                                                                'zipCode',
                                                            );
                                                        }}
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                fieldState.error
                                                            ) {
                                                                return;
                                                            }
                                                            parsePostalCode(
                                                                getValues(
                                                                    'zipCode',
                                                                ) as string,
                                                            );
                                                        }}
                                                        type="button"
                                                        className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[210px]"
                                                    >
                                                        住所自動入力
                                                    </button>
                                                </div>
                                                {errors.zipCode && (
                                                    <span className="error !text-[14px]">
                                                        {errors.zipCode.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                現場：住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'address',
                                                    quoteValidation().address(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input>
                                            {errors.address && (
                                                <span className="error !text-[14px]">
                                                    {errors.address.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="note"
                                    control={control}
                                    render={(props) => {
                                        return (
                                            <>
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    注記
                                                </p>
                                                <textarea
                                                    {...register('note')}
                                                    {...props}
                                                    className="rounded-[2px] border-[1px] border-[#CDD1D5] p-[5px]"
                                                    rows={2}
                                                    maxLength={wordLimit}
                                                ></textarea>
                                                <p className="text-right text-[#00000040]">
                                                    {props.field.value !==
                                                    undefined
                                                        ? props.field.value
                                                              .length
                                                        : 0}
                                                    /{wordLimit}
                                                </p>
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className="d-flex flex-row"
                            style={{
                                marginTop: '14px',
                                justifyContent: 'center',
                            }}
                        >
                            {screenType === 'edit' && (
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    style={{ width: '167.5px' }}
                                    type="button"
                                    onClick={navigateBackToDetail}
                                >
                                    キャンセル
                                </button>
                            )}
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                style={{
                                    marginLeft: '10px',
                                    width: '167.5px',
                                }}
                                type="submit"
                                disabled={isLoading}
                            >
                                保存
                            </button>
                        </div>
                    </form>
                </SPQuoteFormStyles>
            </ConfigProvider>
        </>
    );
};
const SPQuoteForm: FC<ReceivedProps> = (props) => {
    return <SPQuoteFormLayout {...useSPQuoteForm(props)} />;
};
export default SPQuoteForm;
