import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import StyledUserListTable from './styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    userList: any;
    onClickUpdate: any;
    onClickDelete: any;
}

const UserTable = (props: IProps) => {
    const { userList = [], onClickUpdate, onClickDelete } = props;

    return (
        <StyledUserListTable>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                className="text-overflow"
                                align="center"
                            >
                                Email
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow"
                                align="center"
                            >
                                社員名
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow"
                                align="center"
                            >
                                友達リスト表示
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow"
                                align="center"
                            >
                                操作
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList !== null &&
                            userList !== undefined &&
                            userList.map((row, ind) => (
                                <StyledTableRow key={ind}>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        {row?.email}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                    >
                                        {row?.worker?.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                    >
                                        {row?.line_bot_friend?.display_name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                    >
                                        <button
                                            className="update-user-btn"
                                            onClick={() => onClickUpdate(row)}
                                        >
                                            編集
                                        </button>
                                        <button
                                            className="delete-user-btn"
                                            onClick={() => onClickDelete(row)}
                                        >
                                            削除
                                        </button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledUserListTable>
    );
};

export default UserTable;
