import { Box, Typography, CardMedia } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBack from 'assets/menuIcons/arrowBack.png'
import querystring from 'query-string'

const Header2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [headerText, setHeaderText] = useState<string>("");
    const queryObj = querystring.parse(location.search)
    useEffect(() => {
        if (!location || !location.pathname) {
            return;
        }

        if (location.pathname.includes("sp/quote/create")) {
            setHeaderText("見積依頼");
        } else if (location.pathname.includes("sp/quote/history")) {
            setHeaderText("見積履歴");
        } else if (location.pathname.includes('/quote/list/invoice')) {
            setHeaderText('請求書発行');
        } else if (location.pathname.includes("sp/quote/list")) {
            setHeaderText("見積管理");
        } else if (location.pathname.includes("sp/quote/edit")) {
            setHeaderText("編集");
        } else if (location.pathname.includes("sp/quote/detail")) {
            setHeaderText("詳細");
        } else if (location.pathname.includes("sp/invoice")) {
            setHeaderText("請求書");
        } else if (location.pathname.includes("sp/contact")) {
            setHeaderText("");
        } else if (location.pathname.includes("line/customer-info")) {
            setHeaderText("アカウント登録");
        } else if (location.pathname.includes("sp/quote/payment")) {
            setHeaderText("決済入力");
        } else {
            setHeaderText(`${process.env.REACT_APP_PROJECT_TITLE}`);
        }
    }, [location.pathname]);

    // if (window?.location?.pathname == '/login') return <></>;
    // if (location?.pathname === '/') return <></>;
    return (
        <Box
            bgcolor="#215493"
            height={44}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            padding="10px 16px"
            sx={{
                '@media only screen and (min-width: 992px)': {
                    display: 'none',
                },
            }}
        >
            {
                location.pathname.includes("sp/quote/detail") && (
                    <CardMedia
                        component="img"
                        onClick={() => navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`)}
                        sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            position: 'absolute',
                            left: '16px',
                        }}
                        src={process.env.PUBLIC_URL + '/img/vector.svg'}
                        alt="open_menu_icon"
                    />
                )
            }
            {(location.pathname.includes("sp/invoice") || location.pathname.includes("sp/quote/payment")) &&
                <CardMedia
                    component="img"
                    onClick={() => navigate(-1)}
                    sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '16px',
                    }}
                    src={process.env.PUBLIC_URL + '/img/vector.svg'}
                    alt="open_menu_icon"
                />
            }

            {queryObj.top && (location.pathname.includes("sp/quote/history") || location.pathname.includes("/sp/quote/create")
            || location.pathname.includes("/line/customer-info") || location.pathname.includes("/sp/contact")) &&
                <CardMedia
                    component="img"
                    onClick={() => navigate('/line')}
                    sx={{
                        width: '18px',
                        height: '18px',
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '16px',
                    }}
                    src={process.env.PUBLIC_URL + '/img/vector.svg'}
                    alt="open_menu_icon"
                />
            }

            <Typography
                fontWeight={500}
                fontFamily="Zen Kaku Gothic Antique"
                color="#fff"
            >
                {headerText}
            </Typography>
        </Box>
    );
};
export default Header2;
